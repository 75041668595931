<ifc-app-scroll-container class="ifp-dashboard-content">
  <div ngClass.gt-xs="layout margin-md" ngClass.xs="layout margin-sm">

    <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="24px">

      <div fxLayout="column" fxLayoutAlign="center center">

        <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlexFill>
          <span class="mat-headline">{{greeting}}</span>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start start" *ngIf="hasAvailableOffers" fxFlexFill>
          <span class="mat-title">Check out your offers below.</span>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start start" *ngIf="allActiveLoans?.length > 1" fxFlexFill>
          <ifp-dashboard-account-action-buttons [caseFinancingLoans]="activeCFLoans" [otherLoans]="activeLoans" [applications]="applications"
            [currentCustomer]="customer" [isBankVerified]="entity?.isVerified">
          </ifp-dashboard-account-action-buttons>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center start" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center start"
          fxLayoutGap.gt-sm="24px">

          <div fxLayout="column" fxLayoutGap="8px" ngClass.xs="full-width">

            <div fxLayout="column" fxLayoutAlign="center start" *ngIf="nonCfApplications?.length">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" fxFlex="50"
                class="layout padding-vertical-md">
                <div><span class="mat-title">Applications</span></div>
                <div fxLayoutAlign="center">
                  <mat-icon class="mat-orange" matTooltip="Action required" *ngIf="nonCfApplicationsActionRequired">error
                  </mat-icon>
                </div>
              </div>
              <mat-card *ngFor="let application of nonCfApplications; let currentIndex = index;"
                class="mat-elevation-z2 account-widget" [ngClass]="{
                'top-card': nonCfApplications.length > 1 && currentIndex === 0,
                'middle-card': nonCfApplications.length > 1 && currentIndex > 0,
                'bottom-card': nonCfApplications.length > 1 && currentIndex === nonCfApplications.length - 1}">
                <mat-card-content fxLayout="column" fxLayoutAlign="center start">
                  <ifp-dashboard-application-widget [application]="application" [entity]="entity" [isBankLinked]="!noLinkedBanks">
                  </ifp-dashboard-application-widget>
                </mat-card-content>
              </mat-card>
            </div>

            <div fxLayout="column" fxLayoutAlign="center start" *ngIf="cfApplications?.length">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" fxFlex="50"
                class="layout padding-vertical-md">
                <div><span class="mat-title">Case Submission</span></div>
                <div fxLayoutAlign="center">
                  <mat-icon class="mat-orange" matTooltip="Action required" *ngIf="cfApplicationsActionRequired">error
                  </mat-icon>
                </div>
              </div>
              <mat-card *ngFor="let application of cfApplications; let currentIndex = index;"
                class="mat-elevation-z2 account-widget" [ngClass]="{
                'top-card': cfApplications.length > 1 && currentIndex === 0,
                'middle-card': cfApplications.length > 1 && currentIndex > 0,
                'bottom-card': cfApplications.length > 1 && currentIndex === cfApplications.length - 1}">
                <mat-card-content fxLayout="column" fxLayoutAlign="center start">
                  <ifp-dashboard-application-widget [application]="application" [entity]="entity" [isBankLinked]="!noLinkedBanks">
                  </ifp-dashboard-application-widget>
                </mat-card-content>
              </mat-card>
            </div>

            <div fxLayout="column" fxLayoutAlign="center start" *ngIf="loans?.length || accountsFiltered">
              <div fxLayout="row" fxLayoutAlign="space-between center"  fxFlex="50" class="layout padding-vertical-md account-widget">
                <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                  <div>
                    <span class="mat-title">Financing</span>
                  </div>
                  <div fxLayoutAlign="center">
                    <mat-icon class="mat-orange" matTooltip="Action required" *ngIf="financingActionRequired">error
                    </mat-icon>
                  </div>

                </div>

              </div>
              <mat-card class="mat-elevation-z2 account-widget"
                *ngFor="let loan of activeLoans | slice:0:3; let currentIndex = index;"
                [ngClass]="{
                'loc-card': loan?.productCode === productCodes.LOC && activeLoans.length === 1,
                'tl-card': loan?.productCode === productCodes.Term && activeLoans.length === 1,
                'top-card': activeLoans.length > 1 && currentIndex === 0,
                'top-loc-card': loan?.productCode === productCodes.LOC && activeLoans.length > 1 && currentIndex === 0,
                'top-tl-card': loan?.productCode === productCodes.Term && activeLoans.length > 1 && currentIndex === 0,
                'middle-card': activeLoans.length > 1 && currentIndex > 0,
                'middle-loc-card': loan?.productCode === productCodes.LOC && activeLoans.length > 1 && currentIndex > 0,
                'middle-tl-card': loan?.productCode === productCodes.Term && activeLoans.length > 1 && currentIndex > 0,
                'bottom-card': activeLoans.length > 1 && (currentIndex === 2 || currentIndex === activeLoans.length - 1),
                'bottom-loc-card': loan?.productCode === productCodes.LOC && activeLoans.length > 1 && (currentIndex === 2 || currentIndex === activeLoans.length - 1),
                'bottom-tl-card': loan?.productCode === productCodes.Term && activeLoans.length > 1 && (currentIndex === 2 || currentIndex === activeLoans.length - 1)}">
                <mat-card-content fxLayout="column" fxLayoutAlign="center start">
                  <form [formGroup]="form" *ngIf="loans?.length > 3 && currentIndex === 0" class="search" [ngClass]="{
                    'search-collapsed': showAccountsFilter === false,
                    'search-expanded': showAccountsFilter === true}">
                    <ifp-account-search-input [fc]="form.get('search')" (enter)="showAccountsFilter=$event" [excludedProductCode]="productCodes.Law" [entityId]="entity?.id" fxFlexFill (backAction)="closeFilterLoans()" (clearInput)="filterLoans(undefined)" (autocompleteOptionSelected)="filterLoans($event)">
                    </ifp-account-search-input>
                  </form>
                  <ifp-dashboard-account-widget [currentLoan]="loan" [currentCustomer]="customer">
                  </ifp-dashboard-account-widget>
                </mat-card-content>
              </mat-card>
              <div class="message-box info" *ngIf="!activeLoans.length" fxLayout="row" fxLayoutGap="12px"
              fxLayoutAlign="start center">
                <mat-icon class="icon">info</mat-icon>
                <span>No open loans available.</span>
              </div>
              <div class="layout padding-top-sm" fxLayout="row" fxLayoutAlign="end start" fxFlexFill>
                <button mat-button class="secondary-text" *ngIf="loans?.length > 3 || activeLoans?.length < loans?.length" routerLink="/accounts">
                  SEE ALL LOANS
                </button>
              </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="center start" *ngIf="cfLoans?.length || caseFinancingFiltered">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="50" class="layout case-financing-title account-widget">
                <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                  <div>
                    <span class="mat-title">Case Financing</span>
                  </div>
                  <div fxLayoutAlign="center">
                    <mat-icon class="mat-orange" matTooltip="Action required" *ngIf="caseFinancingActionRequired">error</mat-icon>
                  </div>
                </div>
              </div>
              <div *ngIf="firstActiveCFLoan" fxLayout="row" fxLayoutAlign="start center" class="layout available-funds">
                <span class="subtitle-number">{{firstActiveCFLoan?.loanInfo?.availableFunds | shortNumber:'0'}}</span>
                <span class="subtitle secondary-text">available of</span>
                <span class="subtitle-number">{{firstActiveCFLoan?.loanInfo?.msaCreditLimit | shortNumber:'0'}}</span>
                <span class="subtitle secondary-text">line limit</span>
                <mat-icon (click)="showAvailableFundsDialog()">info</mat-icon>
              </div>
              <mat-card class="mat-elevation-z2 account-widget"
                *ngFor="let loan of activeCFLoans | slice:0:3; let currentIndex = index;" [ngClass]="{
                'law-card': activeCFLoans.length === 1,
                'top-card': activeCFLoans.length > 1 && currentIndex === 0,
                'top-law-card': activeCFLoans.length > 1 && currentIndex === 0,
                'middle-card': activeCFLoans.length > 1 && currentIndex > 0,
                'middle-law-card': activeCFLoans.length > 1 && currentIndex > 0,
                'bottom-card': activeCFLoans.length > 1 && (currentIndex === 2 || currentIndex === activeCFLoans.length - 1),
                'bottom-law-card': activeCFLoans.length > 1 && (currentIndex === 2 || currentIndex === activeCFLoans.length - 1)}">
                <mat-card-content fxLayout="column" fxLayoutAlign="center start">
                  <form [formGroup]="form"  class="search" *ngIf="cfLoans?.length > 3 && currentIndex === 0" [ngClass]="{
                    'search-collapsed': showCaseFinanceFilter === false,
                    'search-expanded': showCaseFinanceFilter === true}">
                    <ifp-account-search-input (enter)="showCaseFinanceFilter=$event" [productCode]="productCodes.Law" [entityId]="entity?.id" [fc]="form.get('search')" fxFlexFill (backAction)="closeFilterCFLoans()" (clearInput)="filterCFLoans(undefined)" (autocompleteOptionSelected)="filterCFLoans($event)">
                    </ifp-account-search-input>
                  </form>
                  <ifp-dashboard-account-widget [currentLoan]="loan" [liquidityEventData]="loan.liquidityEventData" [loansTemplates]="cfLoanTemplates" [currentCustomer]="customer">
                  </ifp-dashboard-account-widget>
                </mat-card-content>
              </mat-card>
              <div class="message-box info" *ngIf="!activeCFLoans.length" fxLayout="row" fxLayoutGap="12px"
              fxLayoutAlign="start center">
                <mat-icon class="icon">info</mat-icon>
                <span>No open cases available.</span>
              </div>
              <div class="layout padding-top-sm" fxLayout="row" fxLayoutAlign="end start" fxFlexFill>
                <button mat-button class="secondary-text" *ngIf="(cfLoans?.length > 3 || activeCFLoans?.length < cfLoans?.Length)" routerLink="/case-financing">SEE ALL CASES</button>
                <button mat-button color="primary" (click)="fundAnotherCase()">FUND ANOTHER CASE</button>
              </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="center start"
              *ngIf="banks?.length">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" fxFlex="50"
                class="layout padding-vertical-md">
                <div><span class="mat-title">Banks</span></div>
                <div fxLayoutAlign="center">
                  <mat-icon class="mat-orange" matTooltip="Action required" *ngIf="noLinkedBanks">error</mat-icon>
                </div>
              </div>
              <mat-card *ngFor="let bank of banks; let currentIndex = index;" class="mat-elevation-z2 account-widget"
                [ngClass]="{
                'bank-card': banks.length === 1,
                'top-card': banks.length > 1 && currentIndex === 0,
                'top-bank-card': banks.length > 1 && currentIndex === 0,
                'middle-card': banks.length > 1 && currentIndex > 0,
                'middle-bank-card': banks.length > 1 && currentIndex > 0,
                'bottom-card': banks.length > 1 && currentIndex === banks.length - 1,
                'bottom-bank-card': banks.length > 1 && currentIndex === banks.length - 1}">
                <mat-card-content fxLayout="column" fxLayoutAlign="center start">
                  <ifp-dashboard-bank-widget [bank]="bank" [entity]="entity"></ifp-dashboard-bank-widget>
                </mat-card-content>
              </mat-card>
              <div fxLayout="row" fxLayoutAlign="end start" class="layout padding-top-sm" fxFlexFill>
                <button mat-button color="primary" (click)="link()">
                  <span class="btn-label" *ngIf="!noLinkedBanks">LINK ANOTHER BANK</span>
                  <span class="btn-label" *ngIf="noLinkedBanks">LINK BANK</span>
                </button>
              </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="center start" *ngIf="recentDocuments?.length">
              <div fxLayout="row" fxLayoutAlign="start center" class="layout padding-top-md">
                <div class="mat-title">Recent documents</div>
              </div>
              <mat-card class="mat-elevation-z2 account-widget recent-documents-card">
                <mat-card-content fxLayout="column" fxLayoutAlign="center start">
                  <ifp-recent-documents-widget [recentDocuments]="recentDocuments"></ifp-recent-documents-widget>
                </mat-card-content>
              </mat-card>
              <div fxLayout="row" fxLayoutAlign="end start" class="layout padding-top-sm" fxFlexFill>
                <button mat-button color="primary" routerLink="/documents">
                  <span class="btn-label">SEE ALL DOCUMENTS</span>
                </button>
              </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="center start" *ngIf="recentActivity?.length">
              <div fxLayout="row" fxLayoutAlign="start center" class="layout padding-top-md">
                <div class="mat-title">Recent activity</div>
              </div>
              <mat-card class="mat-elevation-z2 account-widget recent-activity-card">
                <mat-card-content fxLayout="column" fxLayoutAlign="center start">
                  <ifp-recent-activity-widget [recentActivity]="recentActivity"></ifp-recent-activity-widget>
                </mat-card-content>
              </mat-card>
            </div>
          </div>

          <div fxLayout="column" fxLayoutAlign="center center" ngClass.lt-md="layout padding-top-sm" fxLayoutGap="24px"
            *ngIf="messages?.length">
            <div class="header-space" fxHide.lt-md></div>
            <ifp-dashboard-offer-widget *ngIf="renewalEligibilityMessage" [message]="renewalEligibilityMessage" [entity]="entity">
            </ifp-dashboard-offer-widget>
            <ifp-dashboard-offer-widget *ngFor="let message of messages" [message]="message" [entity]="entity"
              (dismiss)="dismissMessage(message)">
            </ifp-dashboard-offer-widget>
            <ifp-advisor-widget></ifp-advisor-widget>
          </div>

        </div>

      </div>

    </div>

  </div>
</ifc-app-scroll-container>
