import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AppBarActionsService {

    private _actions: AppBarAction[] = [];

    get actions() { return this._actions; }
    set actions(value: AppBarAction[]) {
        this._actions = value;
        this._changed.next(value);
    }

    private _changed = new Subject<AppBarAction[]>();
    get changed(): Observable<AppBarAction[]> { return this._changed; }

    enable(id: string, enabled: boolean) {
        const action = this._actions.find(it => it.id === id);
        if (action) {
            action.disabled = !enabled;
            this._changed.next(this._actions);
        }
    }

    hide(id: string, hidden: boolean) {
        const action = this._actions.find(it => it.id === id);
        if (action) {
            action.hidden = hidden;
            this._changed.next(this._actions);
        }
    }

    invoke(id: string) {
        const action = this._actions.find(it => it.id === id);
        if (action)
            this._invoking.next(action);
    }

    label(id: string, label: string) {
        const action = this._actions.find(it => it.id === id);
        if (action) {
            action.label = label;
            this._changed.next(this._actions);
        }
    }

    private _invoking = new Subject<AppBarAction>();
    get invoking(): Observable<AppBarAction> { return this._invoking; }
}

export interface AppBarAction {
    id: string;
    buttonColor?: string;
    buttonAppearance?: string;
    buttonType?: string;
    disabled?: boolean;
    hidden?: boolean;
    label?: string;
    link?: any;
    name?: string;
    icon?: string;
}
