<h1 mat-dialog-title>{{data.title}}</h1>

<mat-dialog-content>
  <p class="secondary-text">
    <span *ngIf="!data.bankIntegrationRequired">
      Thank you for your {{data.action}} in the amount of {{data.transferInfo?.amountInput | currency:'$'}}.
      Your request will be processed within 1-2 business days.
    </span>
    <span *ngIf="data.bankIntegrationRequired">
      Thank you for your {{data.action}} in the amount of {{data.transferInfo?.amountInput | currency:'$'}}.
      Link your bank to speed up the funding process.
      Your request will be processed within 1-2 business days.
    </span>
  </p>
  
  <div mat-dialog-actions class="flex row center-center layout margin-bottom-5">
    <button mat-raised-button [mat-dialog-close]="true" color="primary">
      {{data.closeButtonLabel ? data.closeButtonLabel : 'Ok'}}
    </button>
  </div>

  <div class="content">
    <mat-card class="mat-elevation-z0">
      <div fxLayout="row" fxLayoutAlign="start center" class="card-header">
        <span class="card-title">New account balances</span>
      </div>
      <mat-card-content>
        <mat-list>
          <mat-list-item>
            <span class="secondary-text no-wrap" mat-line>Outstanding balance</span>
            <p class="item-content">{{data.transferInfo?.balanceAfter?.outstandingBalance | currency:'$'}}</p>
          </mat-list-item>
          <mat-divider class="white-border"></mat-divider>
          <mat-list-item>
            <span class="secondary-text no-wrap" mat-line>Payment due</span>
            <p class="item-content">{{data.transferInfo?.balanceAfter?.upcomingPaymentAmount | currency:'$'}}</p>
          </mat-list-item>
          <mat-divider class="white-border"></mat-divider>
          <mat-list-item>
            <span class="secondary-text no-wrap" mat-line>Payment due date</span>
            <p class="item-content" *ngIf="data.transferInfo?.balanceAfter?.upcomingPaymentAmount > 0">{{data.transferInfo?.balanceAfter?.upcomingPaymentDate | date: 'mediumDate'}}</p>
            <p class="item-content" *ngIf="!data.transferInfo?.balanceAfter?.upcomingPaymentAmount || data.transferInfo?.balanceAfter?.upcomingPaymentAmount <= 0">None</p>
          </mat-list-item>
          <mat-divider *ngIf="!data.transferInfo.drawHidden" class="white-border" ></mat-divider>
          <mat-list-item *ngIf="!data.transferInfo.drawHidden">
            <span class="secondary-text no-wrap" mat-line>Credit limit</span>
            <p class="item-content">{{data.transferInfo?.loanOffer?.amount | currency:'$'}}</p>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngIf="!data.transferInfo.drawHidden && data.transferInfo?.balanceAfter?.availableBalance >= data.transferInfo?.amountMinimum">
    <p class="secondary-text">
      See your new available funds below and draw again at any time. Just return to your dashboard, click on DRAW and
      choose your desired amount.
    </p>
    <div fxLayout="column" fxFlexAlign="center center" class="available-funds">
      <div class="amount">{{data.transferInfo?.balanceAfter?.availableBalance | currency:'$'}}</div>
      <div class="mat-caption">Available funds</div>
    </div>
  </div>
</mat-dialog-content>